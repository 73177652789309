<template>
  <v-card>
    <v-card-text>
      <v-row gutters="2" justify="end">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row gutters="2" justify="center">
        <v-col cols="12" md="12">
          <v-data-table :headers="headers" :items="promotions" :search="search">
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  path: '/promotions/edit/' + item.promotionId,
                }"
              >
                <span v-if="!item.name">{{ $t("PROMOTIONS.NO_NAME") }}</span
                >{{ item.name }}
              </router-link>
            </template>
            <template v-slot:[`item.nationalities`]="{ item }">
              <span v-if="item.nationalities === 'ALL_COUNTRIES'">
                {{ $t("PROMOTIONS.ALL_COUNTRIES") }}
              </span>
              <span v-else-if="parseInt(item.nationalities) === 1">
                {{ item.nationalities }} {{ $t("PROMOTIONS.COUNTRY") }}
              </span>
              <span v-else-if="parseInt(item.nationalities) > 1">
                {{ item.nationalities }} {{ $t("PROMOTIONS.COUNTRIES") }}
              </span>
              <span v-else></span>
            </template>
            <template v-slot:[`item.types`]="{ item }">
              <div v-if="parseInt(item.typesTotal) === 1">
                <div v-for="(type, index) in item.types" :key="index">
                  {{ $t(`PROMOTIONS.TYPES.${type}.TITLE`) }}
                </div>
              </div>
              <div v-else-if="parseInt(item.typesTotal) > 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    {{ $t(`PROMOTIONS.TYPES.${item.types[0]}.TITLE`) }}
                    <v-chip primary v-on="on">
                      +{{ parseInt(item.typesTotal) - 1 }}
                    </v-chip>
                  </template>
                  <div>
                    <div v-for="(type, index) in item.types" :key="index">
                      <span v-if="index !== 0"
                        >- {{ $t(`PROMOTIONS.TYPES.${type}.TITLE`) }}
                      </span>
                    </div>
                  </div>
                </v-tooltip>
              </div>
              <div v-else></div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isShowDialogDelete: false,
      search: "",
      headers: [
        {
          text: this.$t("PROMOTIONS.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("PROMOTIONS.TYPE"),
          align: "left",
          sortable: true,
          value: "types",
        },
        {
          text: this.$t("PROMOTIONS.NATIONALITIES"),
          align: "left",
          sortable: true,
          value: "nationalities",
        },
        {
          text: this.$t("PROMOTIONS.BOOKING_EXPIRY"),
          align: "left",
          sortable: true,
          value: "endBookingExpiryFormat",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["promotions"]),
  },
};
</script>
