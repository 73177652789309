<template>
  <v-main>
    <Buttons
      :btn-cancel="false"
      :text-save="$t('PROMOTIONS.NEW_PROMOTION')"
      @save="newPromotion"
    />
    <br />
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="space-around">
              <v-col cols="12" md="12">
                <!-- Tab with tables visibles -->
                <v-tabs>
                  <v-tab @click="getByStatus('ACTIVE')">
                    {{ $t("PROMOTIONS.ACTIVE") }}
                  </v-tab>
                  <v-tab @click="getByStatus('EXPIRED')">
                    {{ $t("PROMOTIONS.EXPIRED") }}</v-tab
                  >
                  <v-tab @click="getByStatus('ALL')">
                    {{ $t("PROMOTIONS.ALL") }}
                  </v-tab>
                  <v-tab-item>
                    <Table />
                  </v-tab-item>
                  <v-tab-item>
                    <Table />
                  </v-tab-item>
                  <v-tab-item>
                    <Table />
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "@/view/pages/promotions/components/Table";
import { mapActions } from "vuex";
import Buttons from "@/view/content/components/Buttons";

export default {
  name: "promotions",
  created() {
    this.getByStatus("ACTIVE");
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PROMOTIONS.TITLE") },
    ]);
  },
  components: { Table, Buttons },
  methods: {
    ...mapActions(["getPromotions"]),
    getByStatus: function(status) {
      this.getPromotions({ params: { status } });
    },
    newPromotion() {
      this.$router.push({ name: "promotions-new" });
    },
  },
};
</script>
